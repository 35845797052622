export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'About',
    headLine: 'A little about me..',
    description: 'I am passionate about making the world a better place. I started my journey in Psychology and quickly switched gears after taking an online coding course. Technology is taking us to exciting new places and I can\'t wait to be a part of it!',
    imgStart: true,
    img: require('../../images/headshot.png').default,
    alt: 'Headshot',
    buttonLabel: 'View LinkedIn',
    btnLink: 'https://www.linkedin.com/in/jazcr/',
    primary: false,
    darkText: true,
    dark: false,
};

export const homeObjTwo = {
    id: 'currentProj',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headLine: 'My current project..',
    description: '3/30/22: I am currently redesigning and developing the landing page for my current employer. I am using Webflow to create this site.',
    buttonLabel: 'Learn About Webflow',
    btnLink: 'https://webflow.com/',
    imgStart: false,
    img: require('../../images/svgDes.svg').default,
    alt: 'Website',
    primary: true,
    darkText: false,
    dark: true,
};


export const homeObjThree = {
    id: 'contact',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headLine: 'Let\'s get in touch!',
    description: 'I am currently searching for a great place to kickstart my Web Devlopment career. Let\'s see if we\'re a good fit for each other.. (;',
    buttonLabel: 'Send an Email',
    btnLink: 'mailto:jazcrudolph@gmail.com',
    imgStart: false,
    img: require('../../images/svgEm.svg').default,
    alt: 'Website',
    primary: true,
    darkText: false,
    dark: true,
}